<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(createNewDriver)">
                    <h3 class="mb-10 font-weight-bold text-dark">
                      Driver Details:
                    </h3>
                    <div class="row">
                      <div class="col-xl-12">
                        <b-form-group label-cols-sm="3" id="input-group-1" label="Name" label-for="input-1">
                          <b-form-input
                            v-model="name"
                            id="input-1"
                            type="text"
                            name="name"
                            class="form-control form-control-lg form-control-solid"
                          >
                          </b-form-input>
                        </b-form-group>

                        <validation-provider rules="required" name="phone" v-slot="{ valid, errors }">
                          <b-form-group
                            label-cols-sm="3"
                            id="input-group-1"
                            label="Phone"
                            label-for="input-2"
                            description="Phone will be used as login username"
                          >
                            <b-input-group size="lg">
                              <b-input-group-prepend>
                                <span class="input-group-text">
                                  <i class="la la-phone"></i>
                                </span>
                              </b-input-group-prepend>
                              <b-form-input
                                v-model="phone"
                                id="input-2"
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="phone"
                                :state="errors[0] ? false : valid ? true : null"
                              ></b-form-input>
                              <b-form-invalid-feedback id="inputLiveFeedback">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider rules="required" name="password" v-slot="{ valid, errors }">
                          <b-form-group
                            label-cols-sm="3"
                            id="input-group-1"
                            label="Password"
                            label-for="input-2"
                            description="Initial password for driver, they can change it in the mobile app"
                          >
                            <b-input-group size="lg">
                              <b-input-group-prepend>
                                <span class="input-group-text">
                                  <i class="la la-lock"></i>
                                </span>
                              </b-input-group-prepend>
                              <b-form-input
                                v-model="password"
                                id="input-2"
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="password"
                                :state="errors[0] ? false : valid ? true : null"
                              ></b-form-input>
                              <b-form-invalid-feedback id="inputLiveFeedback">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Vehicle Number" label-for="input-3">
                          <b-form-input
                            v-model="vehicle_no"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="vehicle_no"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between border-top pt-10">
                      <submit-button :loading="loading" />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createDriver } from "@/api/driver.api";
import { useNotify } from "@/utils/notify";

import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "createDriver",
  components: { ValidationProvider, ValidationObserver },
  setup(_, context) {
    const { notify } = useNotify(context);

    const name = ref("");
    const phone = ref("");
    const password = ref("");
    const vehicle_no = ref("");

    const loading = ref(false);

    const createNewDriver = async function() {
      loading.value = true;

      const payload = {
        name: name.value,
        username: phone.value,
        password: password.value,
        vehicle_no: vehicle_no.value
      };

      try {
        await createDriver(payload);
        notify("Driver created", `The driver is created`);
        context.root.$router.push({ name: "ListDriver" });
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      name,
      phone,
      password,
      vehicle_no,

      createNewDriver,
      loading
    };
  }
};
</script>
