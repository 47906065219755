var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content d-flex flex-column flex-column-fluid"},[_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"row justify-content-center my-10 px-8 my-lg-15 px-lg-10"},[_c('div',{staticClass:"col-xl-12 col-xxl-7"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNewDriver)}}},[_c('h3',{staticClass:"mb-10 font-weight-bold text-dark"},[_vm._v(" Driver Details: ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Name","label-for":"input-1"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-1","type":"text","name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('validation-provider',{attrs:{"rules":"required","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Phone","label-for":"input-2","description":"Phone will be used as login username"}},[_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-input-group-prepend',[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"la la-phone"})])]),_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-2","type":"text","name":"phone","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Password","label-for":"input-2","description":"Initial password for driver, they can change it in the mobile app"}},[_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-input-group-prepend',[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"la la-lock"})])]),_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-2","type":"text","name":"password","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Vehicle Number","label-for":"input-3"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-3","type":"text","name":"vehicle_no"},model:{value:(_vm.vehicle_no),callback:function ($$v) {_vm.vehicle_no=$$v},expression:"vehicle_no"}})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('submit-button',{attrs:{"loading":_vm.loading}})],1)])]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }